<template>
  <tr>
    <td
      class="px-4"
      :style="'width: 20%'"
    >
      <span style="font-size: 12px">({{ productItem.productCode }})</span>
      <br />
      <span>{{ productItem.productName }}</span>
    </td>
    <td
      class="px-4"
      :style="'width: 19%'"
    >
      <div
        class="title-center"
        v-if="productItem.imeiCode"
      >
        <span>{{ productItem.imeiCode }} </span>
        <span
          @click="linkToListImei"
          class="cursor-pointer"
        ><i
            class="flaticon2-pen icon-color text-primary ml-2"
            style="font-size: 1rem;"
          /></span>
      </div>
      <div
        class="title-center"
        v-else-if="
          !productItem.imeiCode &&
            productItem.originalProductType === productType.PRODUCT_IMEI
        "
      >
        <div
          class="mt-2 billCode title-center cursor-pointer"
          @click="linkToListImei"
        >
          <span style="color: blue">Danh sách IMEI</span>
        </div>
      </div>
    </td>
    <td
      class="px-4 text-right"
      :style="'width: 14%;'"
    >
      <div v-if="editable">
        <b-form-input
          :disabled="
            productItem.originalProductType === productType.PRODUCT_IMEI
          "
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="productItem.quantity"
          @input="onChangeQuantity"
          type="number"
          :min="0"
          placeholder="Số lượng"
          class="w-100 h-90 text-right"
        >
        </b-form-input>
      </div>
      <span v-else>{{ productItem.quantity }} </span>
    </td>
    <td
      class="px-4 text-right"
      style="width: 20%"
    >
      <span>{{ formatMoney(productItem.productPrice) }}</span>
    </td>

    <td
      class="px-4 text-right"
      style="width: 15%"
    >
      <span>{{ formatMoney(productItem.totalPrice) }}</span>
    </td>
    <td style="width: 5%">
      <template>
        <div class="d-flex justify-content-center">
          <v-icon
            small
            class="text-danger"
            @click="deleteTransferItem"
            v-b-tooltip
            title="Xóa"
          >mdi-delete</v-icon>
        </div>
      </template>
    </td>
  </tr>
</template>

<style scoped>
.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}

*:focus {
  outline: none;
}
</style>

<script>
import Swal from 'sweetalert2';
import { currencyMask, unMaskPrice } from '../../utils/common';
import { PRODUCT_TYPE } from '../../utils/enum';

export default {
  props: {
    productItem: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedProduct: {
        quantity: 0,
      },
      edit: true,
      error: false,
      mask: currencyMask,
      productType: PRODUCT_TYPE,
    };
  },
  created() {},
  updated() {
    this.checkAddRow();
  },
  methods: {
    onChangeQuantity() {
      this.productItem.totalPrice =
        this.productItem.quantity * this.productItem.productPrice;
      this.$emit('onChangeTransferQuantity');
    },
    linkToListImei() {
      this.$emit('linkToListImei', this.productItem);
    },
    deleteTransferItem() {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này khỏi danh sách không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          const deleteItem = {
            id: this.productItem.id,
            productId: this.productItem.productId,
          };
          this.$emit('deleteTransferItem', deleteItem);
        }
      });
    },
    checkValidation() {
      this.error = false;
      this.edit = true;
      let dataResponse = this.productItem;
      dataResponse.price = unMaskPrice(this.productItem.price);
      this.$emit('update', dataResponse);
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này khỏi phiếu nhập không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.productItem.id);
        }
      });
    },
    checkAddRow() {
      this.edit = true;
    },
    enterClicked() {
      let countLine = 0;
      const tmp = this.productItem.IMEI.split(/\r\n|\r|\n/);
      tmp.forEach((element) => {
        if (element.trim() !== '') {
          countLine++;
        }
      });
      this.productItem.quantity = countLine;
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
  },
};
</script>
